import React from "react";

import data from "./data.json";

import bbb from "./bbb.png";
import nsc from "./nsc.png";
import pvv from "./pvv.jpg";
import vvd from "./vvd.png";

import { differenceInDays } from "date-fns";

function Status() {
  const votingDate = new Date(2023, 10, 23);
  const agreementDate = new Date(2024, 4, 16);
  const today = new Date();

  return (
    <div className="text-center p-10">
      <h1 className="text-4xl font-bold font-sans">
        Hebben we al een kabinet?
      </h1>
      <div className="flex flex-col items-center p-5">
        <div className="flex">
          <img className="w-auto h-52" src={nsc} alt="NSC" />
          <img className="w-auto h-52" src={vvd} alt="VVD" />
        </div>
        <h1 className="text-8xl font-extrabold font-sans p-5">
          {data.government.status ? "Ja 🎉" : "Nee"}
        </h1>
        <div className="flex">
          <img className="w-auto h-52" src={pvv} alt="PVV" />
          <img className="w-auto h-52" src={bbb} alt="BBB" />
        </div>
      </div>
      <div className="pb-10">
        <h2 className="text-2xl font-bold font-sans">
          Maar wel al een regeerakkoord
        </h2>
        <h2 className="text-xl font-sans">
          Dit duurde {differenceInDays(agreementDate, votingDate)} dagen
        </h2>
      </div>
      <div>
        <p className="text-xl">
          De formatie duurt al {differenceInDays(today, votingDate)} dagen
        </p>
        <p>Het record in nederland is 299 (2021-2022)</p>
      </div>
    </div>
  );
}

export default Status;
